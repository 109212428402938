import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/reviewPage.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ReviewHeader = makeShortcode("ReviewHeader");
const ReviewFooter = makeShortcode("ReviewFooter");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ReviewHeader data={props.pageContext.frontmatter} mdxType="ReviewHeader" />
    <h1>{`Overall`}</h1>
    <p>{`Would recommend to a friend, would travel for the feast.
While no longer the reigning Poke champ anymore, you will not be disappointed.
This spot has a special place in our hearts.`}</p>
    <h1>{`Key Points:`}</h1>
    <ul>
      <li parentName="ul">{`The extra ginger has no limit. 🥴`}</li>
      <li parentName="ul">{`They fill you up! 🙌`}</li>
      <li parentName="ul">{`Lots of sauce options, but nothing too creative.`}</li>
      <li parentName="ul">{`The guy working there is kind, communicative and patient. Everything you want in a man.`}</li>
    </ul>
    <h1>{`Biggest Suggestion`}</h1>
    <p>{`Compostable bowls and no more plastic will take you all the way to 6/6. Other than that, don't change. ❤️`}</p>
    <ReviewFooter data={props.pageContext.frontmatter} mdxType="ReviewFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      